<template>
  <div><div class="title">优惠券规则</div><div style="text-align: center; line-height: 20px; margin-top: 10px;">
    发布时间：2022-04-11 20:08:25
  </div><div data-v-0ab10f90="" style="font-size: 14px;"><p>&nbsp;</p>
    <p><strong>什么是优惠券？</strong><br><br>优惠券是{{ $tp().OPEN_COM_NAME }}供应链通过买赠、活动发放等形式发放给用户，用于减免购买支付的惠民措施。<br><br><br><strong>优惠券使用规则</strong><br><br>1. {{ $tp().OPEN_COM_NAME }}发放的商品优惠券仅能在{{ $tp().OPEN_COM_NAME }}提交订单时抵减应支付商品金额（不能抵减运费），不能进行兑现或其他用途；<br>
      <br>2. 不能使用面额超过订单总额的优惠券；<br><br>3. 若在{{ $tp().OPEN_COM_NAME }}台购买商品后发生售后退货，已使用优惠券将作废，不予返还；<br>
      <br>4.目前{{ $tp().OPEN_COM_NAME }}优惠券设有三种使用位置：一是品牌专用，二是全场通用，三是指定商品。品牌专用的优惠券，只能用于减免指定品牌的商品订单金额；全场通用的优惠券可以用于减免{{ $tp().OPEN_COM_NAME }}所有商品订单金额；指定商品的优惠券可以用于减免指定商品订单金额；<br>
      <br>5. {{ $tp().OPEN_COM_NAME }}优惠券只能用于减免满足指定金额的订单。</p>
    <p>&nbsp;</p>
    <hr>
    <p><br><strong>常见问题解答</strong><br><br><br><strong>优惠券有有效期吗？</strong><br>每张优惠券都有有效期，过期无法使用，请珍惜您的每一张优惠券哦。<br><br><br><strong>优惠券可以分开多次使用吗？</strong><br>优惠券只能一次性使用。<br>&nbsp; &nbsp;<br><br><strong>优惠券能多个一起合并使用吗？</strong><br>一个订单只能使用一张优惠券，不可合并多张使用。<br><br><strong>退款后优惠券会返还吗？</strong><br>使用了优惠券的订单，如发生售后退货，已使用优惠券将作废，不予返还。请谨慎使用您的每一张优惠券哦。</p></div></div>
</template>

<script>

export default {
  name: 'RuleOfCoupons'
}
</script>

<style scoped>

</style>
